<template>
  
    <banner :banner="banner" :caption="caption" :path="bannerPath"></banner>

    <div class="container-fluid mt-3 border-bottom-red">
        <div class="row">
            <div class="col-2">
                <div class="content-block">
                    <img class="icon-red icon-left" src="../../assets/images/sekilas-tentang-w.svg" alt="icon">
                </div>
            </div>

            <div class="col-10">
                <div class="content-block">
                    <h3 class="mb-5"> <span class="text-primary">LOWONGAN</span> <span class="text-danger">PEKERJAAN</span></h3>

                    <div v-if="karir.length > 0" class="mb-3">
                        <template v-for="data in karir" :key="data.keyid">
                            <div class="border-bottom-blue">
                                <div class="text-start">
                                    <h6 class="text-judul"> {{ data.judul }} </h6>
                                    <p class="text-waktu"> <timeago :datetime="data.waktu" /> </p>
                                    <small v-html="data.karir.substr(0, 150)"></small>
                                </div>

                                <div class="text-end">
                                    <a>
                                        <router-link :to="'/karir/baca/' + data.kode">
                                            baca selengkapnya <i class="zmdi zmdi-caret-right-circle"></i>
                                        </router-link>
                                    </a>
                                </div>
                            </div>
                        </template>
                    </div>

                    <div v-else>
                        <div class="text-center">
                            <h4 class="text-muted">Belum ada postingan.</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import Banner from '../banner/banner.vue'
import axios from 'axios'

export default {
    name: 'pageKarir',
    components: {
        Banner,
    },
    data () {
        return {
            // bannerPath: 'http://localhost:8080/assets/banner/',
            bannerPath: 'https://api.plnbag.co.id/assets/banner/',
            banner: '',
            caption: '',
            karir: []
        }
    },
    mounted () {
        this.loadBanner()
        this.loadKarir()
    },
    methods: {
        loadBanner () {
            axios.get('karir_banner')
                .then((res) => {
                    this.banner = res.data.data.banner
                    this.caption = res.data.data.caption_indo
                })
        },

        loadKarir () {
            axios.get('karir_list')
                .then((res) => {
                    this.karir = res.data.data
                })
        }
    }
}
</script>

<style scoped>
    .icon-left {
        width: 85px;
        height: auto;
    }

    @media screen and (max-width: 767px) {
        .icon-left {
            width: 45px;
            height: auto;
        }
    }

    .icon-red {
        background-color: #ea2e23;
        border-radius: 5px;
        padding: 10px;
        float: right;
    }

    @media (max-width: 767px) {
        .icon-red {
            float: none;
        }
    }

    .border-bottom-red {
        border-bottom: 5px solid #ea2e23;
    }

    .border-bottom-blue {
        border-bottom: 2px solid #0158a7;
    }

    .text-judul {
        color: #0158a7;
        font-size: 18px;
        font-weight: 500;
    }

    .text-waktu {
        color: #ea2e23;
        font-size: 11px;
        font-weight: 500;
    }

    .text-end a {
        text-decoration: none;
        color: #ea2e23;
    }

    @media screen and (max-width: 767px) {
        .text-end a {
            font-size: 11px;
        }
    }
</style>